<template>
    <div class="container">
        <div class="right_cont">
            <!-- <div class="title flex_center_start_box">
                <div class="title_txt flex_center">
                    <img src="../../assets/img/detail/title_ic4.png" />
                    HISTORY AND EVENTS
                </div>
            </div> -->
            <!-- 只有公司有 -->
            <div class="his_box">
              <div class="head-title" v-if="parentTablenum1.length > 0">Other Official List</div>
                  <div class="persion-table" v-if="parentTablenum1.length > 0">
                      <el-table ref="treeTable" :data="watchlistothertable" border row-key="aaa_id" style="width: 100%" >
                           <!-- 空数据状态的插槽 -->
                           <template slot="empty">
                              <div style="text-align: left">No Date</div>
                          </template> 
                        <el-table-column label="Name" width="800" prop="name" align="left" show-overflow-tooltip :resizable="false">
                              <template slot-scope="scope">
                                  <!-- <div >{{ scope.row.from_date.year}}</div>  -->
                                  <div @click="setid(scope.row)" style="cursor: pointer; color: rgb(18, 144, 201)">
                                      {{ scope.row.name }}
                                  </div>
                              </template>
                          </el-table-column>
                          <el-table-column label="From" prop="" align="left" show-overflow-tooltip :resizable="false">
                              <template slot-scope="scope">
                                  <!-- <div >{{ scope.row.from_date.year}}</div>  -->
                                  <div v-if="scope.row?.from_date?.year > 0">
                                      <!-- {{
                                          new Date(scope.row?.from_date?.year, scope.row?.from_date?.month - 1, scope.row?.from_date?.day).toLocaleDateString('en-GB', {
                                              day: 'numeric',
                                              month: 'short',
                                              year: 'numeric',
                                          })
                                      }} -->
                                                            {{ scope.row?.from_date?.year }}-{{ scope.row?.from_date?.month }}<span v-if="scope.row?.from_date?.day">-</span>{{ scope.row?.from_date?.day }}
                                  </div>
                                  <div v-else>
                                   
                                  </div>
                              </template>
                          </el-table-column>
  
                          <el-table-column label="To" :prop="showTranslate == true ? 'summary' : 'TO'" align="left" show-overflow-tooltip :resizable="false">
                              <template slot-scope="scope">
                                  <!-- <div >{{ scope.row.from_date.year}}</div>  -->
                                  <div v-if="scope.row?.to_date?.year > 0">
                                      <!-- {{
                                          new Date(scope.row?.to_date?.year, scope.row?.to_date?.month - 1, scope.row?.to_date?.day).toLocaleDateString('en-GB', {
                                              day: 'numeric',
                                              month: 'short',
                                              year: 'numeric',
                                          })
                                      }} -->
                                      {{ scope.row?.to_date?.year }}-{{ scope.row?.to_date?.month }}<span v-if="scope.row?.to_date?.day">-</span>{{ scope.row?.to_date?.day }}
                                  </div>
                                  <div v-else>
                                  
                                  </div>
                              </template>
                          </el-table-column>
                      
                      </el-table>
                      <div class="page_i_box">
                              <Page :totalFont="true" :total="page1.total" @onPageChange="onPageChangeList1" @currtentPageChange1="currtentPageChange1"></Page>
                              <div class="search_rsult_txt">{{ page1.pageNo }}-{{ page1.pageSize }} of over {{ page1.total | FilterNum }} results</div>
                          </div>
                  </div>
            </div>
          
        </div>
    </div>
  </template>
  <script>
  import { searchDetailsById } from '@/api/searchApi.js';
  import Page from '@/components/page';
  
  export default {
    components: { Page },
    data() {
        return {
          page1: {
                pageNo: 1,
                pageSize: 10,
                total: 0,
            },
            activities: [],
            Numbersable:[],
            tableListnum: [],
            parentTablenum1: [],
            watchlistothertable: [],
            showTranslate: false,
        };
    },
    computed: {
  headerCellStyle() {
    return {
      backgroundColor: 'red', // 设置背景色
      // 其他样式属性
    };
  },
  },
  
    created() {
        this.getdetail();
    },
    methods: {
  
      pageListid() {
            // console.log(this.parentTable);
  
            this.watchlistothertable = this.parentTablenum1.filter((item, index) => index < this.page1.pageNo * this.page1.pageSize && index >= this.page1.pageSize * (this.page1.pageNo - 1));
            this.page1.total = this.parentTablenum1.length;
        },
        onPageChangeList1(pageNo) {
            // this.pageNo += 1
            this.page1.pageNo = pageNo;
            this.pageListid();
        },
        currtentPageChange1(pageSize) {
            // this.pageNo += 1
            this.page1.pageSize = pageSize;
            // this.init()
            this.pageListid();
        },
   
        getdetail() {
            searchDetailsById({ id: this.$route.query.id }).then(res => {
                console.log(`res`, res);
     
                
                // this.tableListnum = res.data.attributes.entity?.identification_details ? res.data.attributes.entity?.identification_details.concat(nums) : [];
               
        
                this.parentTablenum1 = res.data.attributes?.list_reference?.other_official_lists ? res.data.attributes?.list_reference?.other_official_lists : [];
                this.pageListid();
            });
        },
        setid(row) {
            console.log(`row`, row.sources[0]);
            if (row.sources[0].includes('http')) {
                console.log(`true`, true);
                let routeData = row.sources[0];
                window.open(routeData, '_blank');
            } else {
                let routeData = 'http://' + row.sources[0];
                window.open(routeData, '_blank');
            }
            // let routeData = row.url;
            // window.open(routeData, '_blank');
        },
    },
  };
  </script>
  <style scoped>
  .cont_m {
    margin-top: 30px;
    margin-bottom: 80px;
  }
  .right_cont {
    margin-top: 60px;
  }
  .title {
    margin-bottom: 20px;
  }
  .title_txt {
    font-size: 16px;
    font-family: 'Arial Bold';
  }
  .title_txt img {
    width: 20px;
    margin-right: 8px;
  }
  .pageBox {
    box-shadow: 0px 2px 6px 1px rgba(10, 33, 57, 0.14);
  }
  .his_box {
    width: 100%;
    padding: 30px 30px 30px;
    background: #ffffff;
    box-shadow: 0px 2px 6px 1px rgba(10, 33, 57, 0.14);
    border-radius: 14px 14px 0px 0px;
  }
  .his_box /deep/ .el-timeline-item__wrapper {
    display: flex;
    padding-left: 14px;
  }
  .his_box /deep/ .el-timeline-item__node--normal {
    width: 8px;
    height: 8px;
    left: 1px;
  }
  .his_box /deep/ .el-timeline-item__node {
    background-color: #ff7600;
  }
  .his_box /deep/ .el-timeline-item__tail {
    border-left: 2px dashed #8497ab;
  }
  .his_box /deep/ .el-timeline-item__timestamp.is-top {
    font-size: 16px;
    color: #8497ab;
    padding-top: 0;
  }
  .his_box /deep/ .el-timeline-item__content {
    padding-bottom: 24px;
    font-size: 16px;
    color: #022955;
    border-bottom: 1px solid #e7eaed;
    margin-left: 20px;
    flex: 1;
    line-height: 26px;
    margin-top: -5px;
  }
  .head-title {
    /* color: #022955; */
    font-size: 16px;
    line-height: 35px;
    /* margin-top: 20px; */
    color: #ff7600;
    /* padding-left: 20px; */
    font-weight: 700;
    font-family: 'element-icons';
  }
  .his_box /deep/ .el-table th.el-table__cell{
  background: #F2F5F9;
  }
  </style>
  